import { hesselApi } from '../models';
import { getAPI, postAPI } from '../helpers';
import { APIResponse } from '../types';
import { bookingConsts } from '../../../constants';

const BOOKING_BASE_API_URL = `api/${bookingConsts.BookingApiVersion}/booking`;

export const getVehicleInformation = async (params: hesselApi.GetVehicleInfoParams): APIResponse<hesselApi.GetVehicleInfoResponse> => {
    return getAPI<hesselApi.GetVehicleInfoResponse>(`${BOOKING_BASE_API_URL}/vehicle-information`, params);
};

export const getBenefitAgreement = async (params: hesselApi.GetBenefitAgreementParams): APIResponse<hesselApi.GetBenefitAgreementResponse> => {
    return getAPI<hesselApi.GetBenefitAgreementResponse>(`${BOOKING_BASE_API_URL}/benefit-agreement`, params);
};

export const getWorkshops = async (params: hesselApi.WorkshopParams): APIResponse<hesselApi.WorkshopResponse> => {
    return getAPI<hesselApi.WorkshopResponse>(`${BOOKING_BASE_API_URL}/workshops/${params.vehicleClass}/${params.make}`);
};

export const getServiceProducts = async (params: hesselApi.ServiceProductsParams): APIResponse<hesselApi.ServiceProductsResponse> => {
    return getAPI<hesselApi.ServiceProductsResponse>(`${BOOKING_BASE_API_URL}/service-products`, params);
};

export const getWorkshopServiceAppointmentAvailability = async (
    params: hesselApi.AppointmentAvailabilityParams,
    signal?: AbortSignal
): APIResponse<hesselApi.AppointmentAvailabilityResponse> => {
    return getAPI<hesselApi.AppointmentAvailabilityResponse>(`${BOOKING_BASE_API_URL}/service-appointment-availability`, params, signal);
};

export const postBookServiceAppointment = async (data: hesselApi.BookServiceAppointment): APIResponse<hesselApi.BookServiceAppointmentResponse> => {
    return postAPI<hesselApi.BookServiceAppointmentResponse>(`${BOOKING_BASE_API_URL}/book`, data);
};
